import React from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

const OffCanvasMenu = (props) => {
    const { shared } = props;
    const location = useLocation();
    return (
        <>
            <div className='offcanvas-area'>
                <Link data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"><i className="ri-menu-line"></i></Link>
                <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                    <div className="offcanvas-header">
                        <Link to="/#"><img src={require(`../../../${shared.offCanvasDarkLogo}`)} alt="offCanvasLogo" /></Link>
                        <button type="button" className="offcanvasClose" data-bs-dismiss="offcanvas" aria-label="Close"><i className="ri-close-fill"></i></button>
                    </div>
                    <div className="offcanvas-body">
                        <nav className="navbar navbar-light">
                            <div className="w-100">
                                <div className="collapse navbar-collapse show" id="navbarSupportedContent">
                                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                        <li className="nav-item">
                                            <Link className={location.pathname === '/' ? 'nav-link active' : 'nav-link'} to="/#">Home</Link>
                                        </li>


                                        <li className="nav-item"><Link className={location.pathname === '/privacy-policy' ? 'nav-link active' : 'nav-link'} to="/privacy-policy#">Privacy Policy</Link></li>
                                        <li className="nav-item"><Link className={location.pathname === '/terms' ? 'nav-link active' : 'nav-link'} to="/terms#">Terms and Conditions</Link></li>
                                        <li className="nav-item"><Link className={location.pathname === '/cookie' ? 'nav-link active' : 'nav-link'} to="/cookie#">Cookie Policy</Link></li>

                                    </ul>
                                </div>
                            </div>
                        </nav>
                        <div className="offcanvas-icon-list">

                            {/* <ul>
                                        <li>
                                            <a href={props.shared.contact.social.facebook} target="_blank" rel="noreferrer"><i className="ri-facebook-fill"></i></a>
                                        </li>

                                        <li>
                                            <a href={props.shared.contact.social.linkedin} target="_blank" rel="noreferrer"><i className="ri-linkedin-fill"></i></a>
                                        </li>
                                        <li>
                                            <a href={props.shared.contact.social.instagram} target="_blank" rel="noreferrer"><i className="ri-instagram-fill"></i></a>
                                        </li>

                                    </ul> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OffCanvasMenu;