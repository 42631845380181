import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import ReactGA from 'react-ga4';
import CookieConsent, { COOKIE_KEY } from './main/component/Shared/CookieConsent';
import '../src/assets/fonts/remixicon.css';
import '../src/assets/css/style.css';

// Components
import HomeSix from './main/component/Pages/HomeSix/HomeSix';
// import Contact from './main/component/Pages/Contact/Contact';
// import Faq from './main/component/Pages/Faq/Faq';
import Error404 from './main/component/Pages/Error404/Error404';
import ScrollUpBtn from './main/component/Shared/ScrollUpBtn';
import Terms from './main/component/Pages/Terms/BlogDetails';
import Cookie from './main/component/Pages/Cookie/BlogDetails';
import Privacy from './main/component/Pages/Privacy/BlogDetails';
import shared from './assets/data/shared.json';
import contact from './assets/data/contact.json';
// import faq from './assets/data/faq.json';
import home from './assets/data/home.json';
import privacy from './assets/data/privacy.json';
import terms from './assets/data/terms.json';
import cookie from './assets/data/cookie.json';
import Page404 from './assets/data/404.json';

function App() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID);

    // Set initial consent mode to denied
    ReactGA.gtag('consent', 'default', {
      ad_storage: 'denied',
      analytics_storage: 'denied',
      functionality_storage: 'denied',
      personalization_storage: 'denied',
      security_storage: 'denied',
    });

    // Track page views
    const handleRouteChange = (url) => {
      if (document.cookie.includes(`${COOKIE_KEY}=true`)) {
        ReactGA.send({ hitType: 'pageview', page: url });
      }
    };

    handleRouteChange(location.pathname); // Send initial page view if consent is given

    return () => {
      // Cleanup if needed when component unmounts
    };
  }, [location]);

  const handleAccept = () => {
    // Update consent mode to granted for analytics
    ReactGA.gtag('consent', 'update', {
      analytics_storage: 'granted',
    });

    // Send page view after consent is granted
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname,
      title: document.title,
    });
  };

  return (
    <div>
      <Helmet>
      <title>NoTime - Calender surfing, time travel and age calculator.</title>
        <link rel="shortcut icon" href="favicon.ico" type="image/x-icon" />
      </Helmet>

      <Routes>
        <Route path='/' element={<HomeSix {...home} {...contact} {...shared} />}></Route>
        {/* <Route path='/faq' element={<Faq {...faq} {...shared} />}></Route>
        <Route path='/contact' element={<Contact {...contact} {...shared} />}></Route> */}
        <Route path='/privacy-policy' element={<Privacy {...privacy} {...shared} />}></Route>
        <Route path='/cookie' element={<Cookie {...cookie} {...shared} />}></Route>
        <Route path='/terms' element={<Terms {...terms} {...shared} />}></Route>
        <Route path='*' element={<Error404  {...Page404} {...shared}/>}></Route>
      </Routes>
      <ScrollUpBtn />
      <ToastContainer />
      <CookieConsent onAccept={handleAccept} />
    </div>
  );
}

export default App;
