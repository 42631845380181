import { useState, useEffect } from 'react';

export const COOKIE_KEY = 'notime_app_cookie';
const COOKIE_EXPIRATION_DAYS = 180; // 6 months

const CookieConsent = ({ onAccept }) => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const consent = document.cookie.split('; ').find(row => row.startsWith(COOKIE_KEY));
    if (!consent) {
      setShowBanner(true);
    }
  }, []);

  const handleConsent = () => {
    const now = new Date();
    const expireTime = now.getTime() + COOKIE_EXPIRATION_DAYS * 24 * 60 * 60 * 1000; // 6 months
    now.setTime(expireTime);
    document.cookie = `${COOKIE_KEY}=true; expires=${now.toUTCString()}; path=/`;
    setShowBanner(false);
    if (onAccept) {
      onAccept();
    }
  };

  return (
    showBanner && (
      <div style={styles.banner}>
        <div style={styles.content}>
          This website uses cookies to ensure you get the best experience on our website. By continuing to browse or by clicking "I consent", you agree to the storing of cookies on your device. For more information, please visit our{' '}
          <a href="/cookie" style={styles.link}>Cookie Policy</a> and{' '}
          <a href="/privacy-policy" style={styles.link}>Privacy Policy</a>.
        </div>
        <button onClick={handleConsent} style={styles.button}>
          I Consent
        </button>
      </div>
    )
  );
};

const styles = {
  banner: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    background: '#2B373B',
    color: 'white',
    padding: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 1000,
    boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.3)',
  },
  content: {
    maxWidth: '80%',
    fontSize: '18px',
    lineHeight: '1.5', // Increased line height
  },
  button: {
    background: '#4e503b',
    color: 'white',
    fontSize: '20px',
    padding: '15px 30px',
    border: 'none',
    cursor: 'pointer',
    fontWeight: 'bold',
    borderRadius: '5px',
  },
  link: {
    color: 'cornflowerblue',
    textDecoration: 'underline',
  },
};

export default CookieConsent;
