import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactInfo = (props) => {
    const { contact, shared } = props;

    const initialValues = {
        full_name: '',
        company: '',
        email: '',
        subject: '',
        message: ''
    };

    const validationSchema = Yup.object().shape({
        full_name: Yup.string().required('Full name is required'),
        email: Yup.string().email('Invalid email address').required('Email is required'),
        subject: Yup.string().required('Subject is required'),
        message: Yup.string().required('Message is required')
    });

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);

        try {
            const response = await fetch(process.env.REACT_APP_CONTACT_FORM_API_ENDPOINT, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(values),
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            toast.success('Message sent successfully!');
            resetForm();
        } catch (error) {
            console.error('Error sending message:', error);
            toast.error('Error sending message. Please try again later.');
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <>
            <ToastContainer />
            <section>
                <div className="sc-contact-pages-area sc-pt-140 sc-md-pt-70 sc-pb-20 sc-md-pb-10">
                    <div className="container">
                        <div className="row align-items-top">
                            <div className="col-lg-6">
                                <div className="sc-heading-area sc-mb-20">
                                    <span className="sc-sub-title primary-color">{contact.title}</span>
                                    <h2 className="heading-title sc-mb-45">{contact.subTitle}</h2>
                                </div>
                                {/* <div className="contact-page-service-box">
                                    <div className="contact_icon">
                                        <i className="ri-map-pin-fill"></i>
                                    </div>
                                    <div className="contact-service-box">
                                        <ul>
                                            <li>{shared.contact.address1}</li>
                                        </ul>
                                    </div>
                                </div> */ }
                                <div className="contact-page-service-box">
                                    <div className="contact_icon">
                                        <i className="ri-mail-fill"></i>
                                    </div>
                                    <div className="contact-service-box">
                                        <ul className="contact-list">
                                            <li><a href={`mailto:${shared.contact.email1}`}>{shared.contact.email1}</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="contact-page-service-box sc-md-mb-25">
                                    <div className="contact_icon">
                                        <i className="ri-phone-fill"></i>
                                    </div>
                                    <div className="contact-service-box">
                                        <ul className="contact-list">
                                            <li><a href={`tel:${shared.contact.phone1}`}>{shared.contact.phone1}</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="contact-page-service-box sc-md-mb-25">
                                    <div className="contact_icon">
                                        <i className="ri-whatsapp-fill"></i>
                                    </div>
                                    <div className="contact-service-box">
                                        <ul className="contact-list">
                                            <li><a href={`https://api.whatsapp.com/send?phone=${shared.contact.phone1.replace('+', '')}`}>{shared.contact.phone1}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="contact-box sc-md-mb-10">
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ isSubmitting }) => (
                                            <Form className="login-form">
                                                <div className="form-box">
                                                    <label className="sc-mb-10">Name*</label>
                                                    <Field
                                                        className="from-control"
                                                        type="text"
                                                        name="full_name"
                                                        id="full_name"
                                                        placeholder="Your Firstname and Lastname"
                                                        autoComplete="off"
                                                    />
                                                    <ErrorMessage name="full_name" component="div" className="error" />
                                                </div>
                                                <div className="form-box">
                                                    <label className="sc-mb-10">Company Name</label>
                                                    <Field
                                                        className="from-control"
                                                        type="text"
                                                        id="company"
                                                        name="company"
                                                        placeholder="Your Company Name"
                                                        autoComplete="off"
                                                    />
                                                </div>
                                                <div className="form-box">
                                                    <label className="sc-mb-10">Email*</label>
                                                    <Field
                                                        className="from-control"
                                                        type="email"
                                                        id="email"
                                                        name="email"
                                                        placeholder="Your Email"
                                                        autoComplete="off"
                                                    />
                                                    <ErrorMessage name="email" component="div" className="error" />
                                                </div>
                                                <div className="form-box">
                                                    <label className="sc-mb-10">Subject*</label>
                                                    <Field
                                                        className="from-control"
                                                        type="text"
                                                        id="subject"
                                                        name="subject"
                                                        placeholder="Your Subject"
                                                        autoComplete="off"
                                                    />
                                                    <ErrorMessage name="subject" component="div" className="error" />
                                                </div>
                                                <div className="form-box">
                                                    <label>Message*</label>
                                                    <Field
                                                        as="textarea"
                                                        id="message"
                                                        name="message"
                                                        placeholder="Write Your Message"
                                                        autoComplete="off"
                                                        className="from-control"
                                                    />
                                                    <ErrorMessage name="message" component="div" className="error" />
                                                </div>
                                                <div className="submit-button">
                                                    <button
                                                        className="submit-btn"
                                                        type="submit"
                                                        disabled={isSubmitting}
                                                    >
                                                        {isSubmitting ? 'Sending...' : 'Send Message'}
                                                    </button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContactInfo;
