import React from 'react';
import { Fade } from 'react-reveal';
import businessImg from '../../../../assets/images/banner/business_img.png'
import shapeImage from '../../../../assets/images/banner/shape_image.png'

const Business = (props) => {
    const {home} = props;
    return (
        <>
            <section className="sc-business-section-area dark-bg-color sc-pt-140 sc-md-pt-70 sc-pb-140 sc-md-pb-80">
                <div className="container">
                    <div className="row align-items-center">
                        <Fade left>
                            <div className="col-lg-6">
                                <div className="sc-business-item sc-pr-135 sc-md-mb-150 sc-md-pr-0">
                                    <div className="sc-heading-area sc-mb-30 sc-sm-mt-30">
                                        <h2 className="heading-title white-color sc-mb-25">{home.heroSection.title}</h2>
                                        <div className="white-dark-color">
                                        {home.heroSection.description}
                                        </div>
                                    </div>
                                    <div className="sc-business-check-box">
                                        <ul className="check-list">
                                        {
                                                    home.heroSection.highlights.map((item, index) => {
                                                        return (<li key={index}>
                                                            <i className="ri-checkbox-circle-fill"></i><span> {item.text}</span>
                                                        </li>)
                                                    })
                                                }
                                        </ul>
                                    </div>
                                    <div className="banner-btn-area d-flex align-items-center sc-mt-35 sc-mb-35">
                                        <a className="sc-yellow-btn yellow-btn2 sc-mr-15" href={home.heroSection.appStoreLink} target="_blank" rel="noreferrer">
                                            <span> <i className="ri-apple-fill"></i> {home.heroSection.appStoreButtonText}</span>
                                        </a>
                                        <a className="sign-in-btn" href={home.heroSection.playStoreLink} target="_blank" rel="noreferrer">
                                            <span> <i className="ri-google-play-fill"></i> {home.heroSection.playStoreButtonText}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="col-lg-6">
                                <div className="business-image-area">
                                    <img className="business-img" src={require(`../../../../${home.heroSection.image}`)} alt={home.heroSection.label} />
                                    <img className="business-img_1" src={shapeImage} alt="Business" />
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Business;