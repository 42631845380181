import React from 'react';

const FaqQuestion = (data) => {
    return (
        <>
            <div className="sc-faq-pages-area sc-pt-130 sc-md-pt-70 sc-pb-10 sc-md-pb-10">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3">
                            <div className="sc-heading text-center sc-mb-60">
                                <h2 className="sc-mb-20">{data.faq.title}</h2>
                                <div className="description">
                                {data.faq.subTitle}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 offset-lg-2">
                            <div className="accordion sc-faq-style" id="accordionExample">
                                
                        {
                            data.faq.itemList.map((item, index) => {
                                return (
                                    <div className="accordion-item" key={index}>
                                    <h2 className="accordion-header" id={`heading-${index}`}>
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#collapse-${index}`}
                                            aria-expanded="true"
                                            aria-controls={`collapse-${index}`}>
                                           {item.titleText}
                                        </button>
                                    </h2>
                                    <div id={`collapse-${index}`} className="accordion-collapse collapse" aria-labelledby={`heading-${index}`} data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                        {item.description}
                                        </div>
                                    </div>
                                </div>
                                )
                            })
                        }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FaqQuestion;