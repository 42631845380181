import React from 'react';
import Header from '../../Shared/Header';
import Footer from '../../Shared/Footer';
import Helmet from 'react-helmet';
import Business from './Business';
import ContactInfo from './ContactInfo';
const HomeSix = (data) => {
    return (
        <>
            <Helmet>
                <title>{data.home.pageTitle}</title>
                {
                    data.home.meta.map((item, index) => {
                        if (item.property) {
                            return <meta property={item.property} content={item.content} key={index} />;
                          } else if (item.name) {
                            return <meta name={item.name} content={item.content} key={index} />;
                          } else {
                            return null;
                          }
                    } )
                }
            </Helmet>
            <Header
                parentMenu='home'
                headerClass='sc-header-section sc-header-section6'
                {...data}
            />
            <Business {...data} />
            <ContactInfo {...data} />
            <Footer
                footerStyle="sc-footer-style-three"
                {...data}
            />
        </>
    );
};

export default HomeSix;