import React from 'react';
import Header from '../../Shared/Header';
import BreadCums from '../../Shared/BreadCums';
import FaqQuestion from './FaqQuestion';
import Footer from '../../Shared/Footer';
import Home4Apps from '../../Shared/Home4Apps';

const Faq = () => {
    return (
        <>
            <Header
                parentMenu='pages'
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums pageTitle='Faq' />
            <FaqQuestion />
            <Home4Apps />
            <Footer />
        </>
    );
};

export default Faq;