import React from 'react'
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";

const ClientTestimonial = (data) => {

    const { home } = data;

    const CustomRightArrow = ({ onClick }) => {
        return <button className='commonArrow arrowRight' onClick={() => onClick()}><i className="ri-arrow-right-line"></i></button>;
    };

    const CustomLeftArrow = ({ onClick }) => {
        return <button className='commonArrow arrowLeft' onClick={() => onClick()}><i className="ri-arrow-left-line"></i></button>;
    };

    return (
        <>
            <section className="sc-client-section-area yellow-bg sc-pt-125 sc-md-pt-65 sc-pb-140 sc-md-pb-80">
                <div className="container">
                    <div className="client-testimonial-wrap">
                        <div className="row">
                            <div className="col-md-6 offset-md-3">
                                <div className="sc-heading-area sc-mb-55 sc-md-mb-45 text-center">
                                    <h2 className="heading-title sc-mb-25">{home.customerReviews.titleText}</h2>
                                </div>
                            </div>
                        </div>

                        <div className='client-carousel-wrapper'>
                            <Carousel className="client-carousel"
                                infinite={true}
                                draggable={true}
                                arrows={true}
                                pauseOnHover={true}
                                slidesToSlide={2}
                                swipeable={true}
                                autoPlay={true}
                                autoPlaySpeed={3000}
                                customRightArrow={<CustomRightArrow />}
                                customLeftArrow={<CustomLeftArrow />}
                                responsive={{
                                    desktop: {
                                        breakpoint: {
                                            max: 3000,
                                            min: 992
                                        },
                                        items: 3,
                                    },
                                    tablet: {
                                        breakpoint: {
                                            max: 991,
                                            min: 577
                                        },
                                        items: 2,
                                    },
                                    mobile: {
                                        breakpoint: {
                                            max: 576,
                                            min: 1
                                        },
                                        items: 1,
                                    }
                                }}>
                                    {
                                        home.customerReviews.itemList.map((item, index) => {
                                            return (
                                                <div className="sc-client-item" key={index}>
                                                <div className="client-rating sc-mb-15">
                                                    <ul>
                                                    {Array.from({ length: item.stars }, () => (
                                                        <li key={Math.random()}>
                                                        <i className="ri-star-fill"></i>
                                                    </li>
                                                    ))}
                                                    </ul>
                                                </div>
                                                <div className="description sc-mb-15">
                                                    {item.reviewText}
                                                </div>
                                                <div className="sc-client-author d-flex align-items-center">
                                                        <div className="author-text">
                                                        <h5 className="client-title sc-mb-0">{item.name}</h5>
                                                        <span>{item.jobTitle}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            );
                                        })
                                    }

                            </Carousel>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ClientTestimonial;