import React from 'react';
import { Fade } from 'react-reveal';
import { HashLink as Link } from 'react-router-hash-link';

const Footer = (props) => {
    return (
        <>
            <section className="sc-footer-section sc-pt-200 sc-md-pt-170 sc-sm-pt-100">
                <div className="container">
                    <div className="row padding-bottom">
                        <Fade fade delay={100}>
                            <div className="col-lg-4 col-sm-6">
                                <div className="footer-about">
                                    <div className="footer-logo sc-mb-25">
                                        <Link to="#"><img src={require(`../../../${props.shared.footerLogo}`)} alt="FooterLogo" /></Link>
                                    </div>
                                    <p className="footer-des">{props.shared.footerText}</p>
                                    {/* <ul className="about-icon">
                                        <li>
                                            <a href={props.shared.contact.social.facebook} target="_blank" rel="noreferrer"><i className="ri-facebook-fill"></i></a>
                                        </li>

                                        <li>
                                            <a href={props.shared.contact.social.linkedin} target="_blank" rel="noreferrer"><i className="ri-linkedin-fill"></i></a>
                                        </li>
                                        <li>
                                            <a href={props.shared.contact.social.instagram} target="_blank" rel="noreferrer"><i className="ri-instagram-fill"></i></a>
                                        </li>

                                    </ul> */}
                                </div>
                            </div>
                        </Fade>
                        <Fade fade delay={200}>
                            <div className="col-lg-4 col-sm-6 sc-xs-mt-40">
                                <div className="footer-menu-area sc-pl-90 sc-lg-pl-0 sc-md-pl-0 sc-md-mb-30 sc-sm-mb-0">
                                    <h4 className="footer-title white-color sc-md-mb-15">Company</h4>
                                    <ul className="footer-menu-list">
                                        <li><Link to="/#">Home</Link></li>
                                        <li><Link to="/privacy-policy#">Privacy Policy</Link></li>
                                        <li><Link to="/cookie#">Cookie Policy</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </Fade>
                        <Fade fade delay={400}>
                            <div className="col-lg-4 col-sm-6 sc-sm-mt-40">
                                <div className="footer-menu-area footer-menu-area-left sc-pl-65 sc-lg-pl-0 sc-md-pl-0">
                                    <h4 className="footer-title white-color sc-md-mb-15">Get In Touch</h4>
                                    <ul className="footer-menu-list">
                                        <li><i className="ri-mail-fill"></i><a href={`mailto:${props.shared.contact.email1}`}>{props.shared.contact.email1}</a></li>
                                        {/* <li><i className="ri-phone-fill"></i><a href={`tel:${props.shared.contact.phone1}`}>{props.shared.contact.phone1}</a></li> */}
                                        {/* <li className="footer-map"><i className="ri-map-pin-fill"></i><span>{props.shared.contact.address1}</span></li> */}
                                    </ul>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
                <div className="copyright-area">
                    <div className="container">
                        <div className="border-top"></div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="copyright-text text-center">
                                    <p>{props.shared.copyright}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Footer;