import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

const BlogDetailsData = (props) => {
    const { cookie } = props
    return (
        <>
            <section>
                <div className="sc-blog-details sc-pt-140 sc-md-pt-80 sc-pb-20 sc-md-pb-20">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9">
                                <h2 className="blog-title sc-pt-15 sc-mb-15 fw-bold">
                                    {cookie.company.alias}   {cookie.title}
                                </h2>
                                <div className="blog-list-menu list_menu sc-pt-20 sc-mb-15">
                                    <ul className="blog-list">
                                        <li><i className="ri-edit-fill"></i> Last Updated: {cookie.dateUpdated}</li>
                                    </ul>
                                </div>
                                <div className="blog-item sc-mt-25">
                                    <div className="blog-image sc-mb-30">
                                        <Link smooth to="#"><img src={require(`../../../../${cookie.bigPicture}`)} alt={cookie.title} /></Link>
                                    </div>
                                    <div className="blog-content p-0">
                                        <div className="blog_date">
                                            <ul>
                                                <li>{cookie.dateUpdated.split(' ')[0]}</li>
                                                <li>{cookie.dateUpdated.split(' ')[1]}</li>
                                                <li>{cookie.dateUpdated.split(' ')[2]}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="details-content-area sc-md-mb-50">
                                    <div className="details-content">

                                        <p className="description sc-mb-25">
                                     {cookie.company.alias} employs cookies on its website located at {cookie.company.website}. These cookies are tiny text files that are stored on your device when you access or interact with our website. By using cookies, we are able to gather information on your website usage and retain your preferences and activities. Our goal is to enhance the user experience on our website by utilizing cookies. Additionally, cookies and the information gathered through them are utilized to monitor and analyze the website's usability and usage, ultimately leading to improvements on the website.

                                     <br/> <br/>Cookies include a distinct identifier (UID), like a randomly generated number, which allows for device identification. While cookies can identify your device, they typically do not provide enough information to identify our website users on their own. Nevertheless, certain third parties, like Facebook, may link the UID from the cookie to the corresponding Facebook user if the user has a Facebook account and visits our website.

                                     <br/> <br/>Our site uses cookies essential for its operation. Further elaborated, certain optional cookies employed on our site are vital for enabling functionalities, enhancing performance or user experience, and analyzing website usage and users to provide targeted digital marketing.
                 
                                     <br/> <br/>By accessing our website, cookies may be placed on your device by us and/or third-party service providers like Google or Facebook.
                                </p>

                                <h2 >Data collected by cookies</h2>

                                <p className="description sc-mb-25">
                                    We and third-party service providers specifically gather the subsequent information through cookies:
                                </p>
                                <ul className="sc-mb-25" style={{listStyleType: 'circle', marginLeft: '20px'}}>
                                    <li>IP address</li>
                                    <li>Visiting time</li>
                                    <li>Website through which you have moved to our website</li>
                                    <li>Visited webpages</li>
                                    <li>Browser type (such as Internet Explorer, Firefox)</li>
                                    <li>Geographical location</li>
                                </ul>

                                <h2 >Cookies that we use on our website</h2>

                                <p className="description sc-mb-25">
                                    The following cookies are used on our website. We use
                                </p>
                                <ul className="description sc-mb-25">
                                    <li>
                                        Google services: YouTube for content embedding, Google Analytics for website monitoring, Google Ads for remarketing, and Google Tag Manager for functional purposes. Cookies related to these services are placed on your device by Google. Google may also have access to these cookies and the data collected by them. For more information, please visit 
                                        <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage" aria-label="Google services, including their expiration date: External site" target="_blank" rel="noopener noreferrer">Google services, including their expiration date
                                            <svg aria-hidden="true" style={{ marginLeft: '.5rem', marginRight: '.2rem', width: '12px', height: '12px' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 9"><path d="M4.499 1.497h4v4m0-4l-7 7" fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                        </a> and
                                        <a href="https://policies.google.com/technologies/cookies" aria-label="Information about Google’s services in general: External site" target="_blank" rel="noopener noreferrer">information about Google's services in general 
                                            <svg aria-hidden="true" style={{ marginLeft: '.5rem', marginRight: '.2rem', width: '12px', height: '12px' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 9"><path d="M4.499 1.497h4v4m0-4l-7 7" fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                        </a>.
                                    </li>
                                    {/* <li>
                                        Twitter is used for enabling the share button and user monitoring on our website. Twitter places these cookies on your device. Twitter may also have access to these cookies and the data collected by them. For further details, please refer to the 
                                        <a href="https://help.twitter.com/en/rules-and-policies/twitter-cookies" aria-label="Twitter cookies: External site" target="_blank" rel="noopener noreferrer"> Twitter cookies policy
                                            <svg aria-hidden="true" style={{ marginLeft: '.5rem', marginRight: '.2rem', width: '12px', height: '12px' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 9"><path d="M4.499 1.497h4v4m0-4l-7 7" fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                        </a>.
                                    </li>
                                    <li>
                                        Facebook is utilized for enabling the share button and user monitoring on our website. Facebook places these cookies on your device. Facebook may also have access to these cookies and the data collected by them. Facebook uses this data to provide analytics services and target digital marketing. For more information, please visit the 
                                        <a href="https://www.facebook.com/policy/cookies/" aria-label="Facebook cookies: External site" target="_blank" rel="noopener noreferrer"> Facebook cookies policy
                                            <svg aria-hidden="true" style={{ marginLeft: '.5rem', marginRight: '.2rem', width: '12px', height: '12px' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 9"><path d="M4.499 1.497h4v4m0-4l-7 7" fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                        </a>.
                                    </li>
                                    <li>
                                        LinkedIn is employed for enabling the share button and user monitoring on our website. To learn more about LinkedIn cookies, please refer to the 
                                        <a href="https://www.linkedin.com/legal/cookie-policy" aria-label="LinkedIn cookies: External site" target="_blank" rel="noopener noreferrer"> LinkedIn cookies policy 
                                            <svg aria-hidden="true" style={{ marginLeft: '.5rem', marginRight: '.2rem', width: '12px', height: '12px' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 9"><path d="M4.499 1.497h4v4m0-4l-7 7" fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                        </a>.
                                    </li>
                                    <li>
                                        Hotjar is utilized for monitoring our website. To learn more about Hotjar cookies, please refer to the 
                                        <a href="https://www.hotjar.com/legal/policies/cookie-information" aria-label="Hotjar cookies, including their expiration date: External site" target="_blank" rel="noopener noreferrer"> Hotjar cookies policy 
                                            <svg aria-hidden="true" style={{ marginLeft: '.5rem', marginRight: '.2rem', width: '12px', height: '12px' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 9"><path d="M4.499 1.497h4v4m0-4l-7 7" fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                        </a>.
                                    </li>
                                    <li>
                                        HubSpot is used for user monitoring, functionalities of the forms available on our website, as well as automation of marketing. HubSpot places these cookies on your device. HubSpot may also have access to HubSpot cookies and the data collected by them. For more details, please visit the 
                                        <a href="https://knowledge.hubspot.com/articles/kcs_article/reports/what-cookies-does-hubspot-set-in-a-visitor-s-browser?__hstc=189999476.3cefa2feedb53d8ae4f21b76eefc42f7.1711292839224.1711918457414.1712440064722.6&amp;__hssc=189999476.4.1712440064722&amp;__hsfp=266263988" aria-label="HubSpot cookies, including their expiration date: External site" target="_blank" rel="noopener noreferrer"> HubSpot cookies policy
                                            <svg aria-hidden="true" style={{ marginLeft: '.5rem', marginRight: '.2rem', width: '12px', height: '12px' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 9"><path d="M4.499 1.497h4v4m0-4l-7 7" fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                        </a>.
                                    </li>
                                    <li>
                                        Leadfeeder is employed for identifying our website visitors. To learn more about Leadfeeder cookies, including their expiration date, please refer to the 
                                        <a href="https://www.leadfeeder.com/cookies-and-tracking/" aria-label="Leadfeeder cookies, including their expiration date: External site" target="_blank" rel="noopener noreferrer"> Leadfeeder cookies policy
                                            <svg aria-hidden="true" style={{ marginLeft: '.5rem', marginRight: '.2rem', width: '12px', height: '12px' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 9"><path d="M4.499 1.497h4v4m0-4l-7 7" fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                        </a>.
                                    </li>
                                    <li>
                                        WordPress: Our website is built on the WordPress platform. WordPress places both necessary and optional cookies for functional purposes.
                                    </li>
                                    <li>
                                        Soundcloud is used to embed our podcast on the website. Soundcloud places cookies for functional and user monitoring purposes, and it may also have access to these cookies and the data collected by them. To learn more, please refer to the 
                                        <a href="https://developers.soundcloud.com/docs/api/cookies" aria-label="Soundcloud cookies: External site" target="_blank" rel="noopener noreferrer"> Soundcloud cookies policy
                                            <svg aria-hidden="true" style={{ marginLeft: '.5rem', marginRight: '.2rem', width: '12px', height: '12px' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 9"><path d="M4.499 1.497h4v4m0-4l-7 7" fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                        </a>.
                                    </li>
                                    <li>
                                        Vimeo is used to embed videos on the website. Vimeo places cookies for functional and user monitoring purposes, and it may also have access to these cookies and the data collected by them. For more information, please refer to the 
                                        <a href="https://vimeo.com/cookie_policy" aria-label="Vimeo cookies: External site" target="_blank" rel="noopener noreferrer"> Vimeo cookies policy
                                            <svg aria-hidden="true" style={{ marginLeft: '.5rem', marginRight: '.2rem', width: '12px', height: '12px' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 9"><path d="M4.499 1.497h4v4m0-4l-7 7" fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                        </a>.
                                    </li> */}
                                </ul>


                                <h2 >Managing Cookies</h2>

                                <p className="description sc-mb-25">
                                    Essential cookies are automatically stored on your device for site functionality. Your cookie preferences determine which cookies are stored on your device. If you previously agreed to cookies but want to change your settings, you can manage them by selecting "Cookie settings" at the bottom of the website.
                                </p>
                                <p className="description sc-mb-25">
                                    Utilize your web browser preferences to control cookies. Be aware that disabling all cookies could impact your website experience and limit access to certain features. Refer to your browser's user manual for details on adjusting cookie settings.
                                </p>

                                <h2 >Updates and contact information</h2>

                                <p className="description sc-mb-25">
                                    We retain the authority to revise and modify our Cookie Policy. In the absence of any legal obligations, we are not obligated to notify the visitors of our website regarding any alterations made to our Cookie Policy. It is advisable to review this policy periodically.
                                </p>
                                <p className="description sc-mb-25">
                                    Should you believe that we have not adhered to this Cookie Policy or if you have any inquiries, please reach out to us via email at <a href={`mailto:${cookie.company.contactEmail}`}>{cookie.company.contactEmail}</a>, and we will respond promptly.
                                </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default BlogDetailsData;